@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: "Poppins", sans-serif;
}

.submit-button {
  width: fit-content;
  margin-top: 0 !important;
  @apply bg-[#35c680] outline-none rounded-[30px] text-white px-[25px] py-[13px];
}

.login-button {
  width: fit-content;
  @apply bg-[#35c680] outline-none rounded-[30px] text-white px-[31px] py-[11px] mt-[10px];
}

.drop-shadow {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
}

.input-text-length-indicator {
  @apply absolute top-[30%] mt-[-7px] right-[20px] text-[12px] leading-[18px] text-[#B9B9B9];
}

.textarea-text-length-indicator {
  @apply absolute top-[24px] mt-[-7px] right-[20px] text-[12px] leading-[18px] text-[#B9B9B9];
}
