/* input[type="text"],
input[type="number"],
textarea,
select {
  height: 50px;
  border-radius: 4px;
  border: 1px solid #b9b9b9;
  font-size: 14px;
  color: #414141;
  padding: 15px 20px;
  margin-bottom: 30px;
  width: 100%;
} */

.multi-select {
  height: 50px;
}

.dropdown-container {
  height: 50px;
}

.dropdown-heading-value {
  font-size: 14px;
  color: #414141;
}

.dropdown-heading {
  height: 100% !important ;
  /* padding: 15px 20px !important; */
}


/* button {
  background-color: #35c680;
  outline: none;
  border-radius: 30px;
  color: white;
  padding: 13px 25px;
  width: fit-content;
  margin-top: 10px;
} */

.dropdown-container[aria-labelledby="Ingredients"] {
  max-width: 862px;
  width: 862px;
}

.rmsc .ingredient-select .dropdown-container .dropdown-heading {
  height: auto !important;
}
