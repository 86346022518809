
th {
  color: #6F6F6F;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  text-align: start;
}

td {
  color: #414141;
  font-weight: 400;
  font-size: 13px;
  /* line-height: 20px; */
}

.dropdown-container[aria-labelledby="Quantity"] {
  max-width: 104px;
  width: 104px;
  height: 36px;
  margin-bottom: 0;
  padding-bottom: 0;
}

/* input {
  height: 36px;
  border-radius: 4px;
  border: 1px solid #b9b9b9;
  font-size: 14px;
  color: #414141;
  padding: 15px 20px;
  margin-bottom: 30px;
  width: 100%;
} */

.rmdc .ingredient-select .dropdown-heading {
  padding: 0px;
}