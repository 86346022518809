.input {
  height: 50px;
  border-radius: 4px;
  border: 1px solid #b9b9b9;
  font-size: 14px;
  color: #414141;
  padding: 15px 20px;
  margin-bottom: 30px;
  width: 100%;
}

.textarea {
  padding-right: 77px !important;
}

form {
  max-width: 568px;
  width: 568px;
}

.multi-select {
  height: 50px;
}

.dropdown-container {
  height: 50px;
  margin-bottom: 30px !important;
}

.dropdown-heading-value {
  font-size: 14px;
  color: #414141;
}

.dropdown-heading {
  height: 50px !important;
  /* padding: 15px 20px !important; */
}

/* p {
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #2f0842;
  margin-bottom: 10px;
} */

/* button[type="submit"] {
  background-color: #35c680;
  outline: none;
  border-radius: 30px;
  color: white;
  padding: 13px 25px;
  width: fit-content;
  margin-top: 10px;
} */
